let modal;
let links;
let overlay;

function openModal() {
    modal.classList.add('open');
    overlay.classList.add('open');
}

function closeModal() {
    modal.classList.remove('open');
    overlay.classList.remove('open');
}

function init() {
    modal = document.querySelector('.modal');
    overlay = document.querySelector('.modal__overlay');

    if (!modal) {
        return;
    }

    
    links = [].slice.call(document.querySelectorAll('a[href="#modal"]'));

    links.forEach(l => {
        l.addEventListener('click', openModal, false);
    })

    overlay.addEventListener('click', closeModal, false);
}

export {
    init
}