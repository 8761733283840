import * as Type from './_type';
import * as Gyro from './_gyro';
import * as Modal from './_modal';

function init() {
    // Type.init();
    // Gyro.init();
    Modal.init();
}
if (typeof window !== 'undefined') {
    if (document.readyState == 'complete' || document.readyState == 'interactive') {
        init();
    } else {
        document.addEventListener('DOMContentLoaded', init, false);
    }
}