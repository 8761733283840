/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import {  Link } from "gatsby"

import '../js/main.js'

const Layout = ({ children }) => {

  return (
    <>
      <Link className='home' to='/'><span role='img' aria-label="Home">🏠</span></Link>
      {children}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
